!(function () {
  this.BDMPerformance = function () {
    //define your this.variables here!

    this.init();
  }

  //Plugins to be initiated
  BDMPerformance.prototype.init = function () {
    this.initToggleMenu();
    this.initSwipebox();
    this.initCarousel();
    this.initInstagram();
    this.initMasks();
    this.initCollapsible();
    this.initShowModelTable();
  }

  /**********
  * Plugins *
  **********/

  // open mobile menu
  BDMPerformance.prototype.initToggleMenu = function () {
    $('.js-toggle-menu').click(function(e){
      $('nav').toggleClass('open');
    });
  }

  //Zoom IMG - Swipebox
  BDMPerformance.prototype.initSwipebox = function () {
    ;( function( $ ) {
      $( '.swipebox' ).swipebox();
    } )( jQuery );
  }

  //carousel INDEX
  BDMPerformance.prototype.initCarousel = function () {
    $(document).ready(function(){
      $('.slick').slick({
        slidesToShow: 4,
        autoplay: true,
        dots: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              autoplay: true,
              slidesToShow: 1,
              arrows: false,
              dots: true
            }
          }
        ]
      });
    });
  }

  //Instagram
  BDMPerformance.prototype.initInstagram = function () {
    $(function() {
      //Get token here -> http://instagram.pixelunion.net/
      //Get ID here -> https://smashballoon.com/instagram-feed/find-instagram-user-id/
      var user_id = '1127363368';
      var token = '1127363368.1677ed0.7d1deac9c4284eef824176a4f86c0abb';

      var url = 'https://api.instagram.com/v1/users/'+user_id+'/media/recent/?access_token='+token;

      $.ajax({
        type: 'POST',
        dataType: 'jsonp',
        url: url
      }).done(function(response) {
        var classe;
        $.each(response.data, function(index, value) {
          if (index > 19) return false;

          var html = '\
          <div class=\"col l3 m4 s12\">\
            <a href=\"'+value.link+'\" target=\"_blank\" class=\"img-instagram--block\">\
              <img src=\"'+value.images.low_resolution.url+'\" title=\"'+value.caption.text+'\"\
                   class=\"img-instagram\"/>\
            </a>\
          </div>\
          ';

          $('#iframe-instagram').append(html);
        });
      });
    });
  }


  //Mask de Telefone
  BDMPerformance.prototype.initMasks = function () {
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function(val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };
    if ($('input[name=phone]').length > 0)
      $('input[name=phone]').mask(SPMaskBehavior, spOptions);

    if ($('input[name=cpf]').length > 0)
      $('input[name=cpf]').mask('000.000.000-00');
  }


  $('.cnpj-mask').mask('00.000.000/0000-00', {reverse: true});

  $('.cep-mask').mask('00000-000');


  //Collapsible
  BDMPerformance.prototype.initCollapsible = function () {
    var collapsibleTrigger = document.querySelectorAll('.js-collapsible__trigger');

    for (var i = 0; i < collapsibleTrigger.length; i++) {
      collapsibleTrigger[i].onclick = function () {

        if ( ! this.parentNode.classList.contains('active')) {
          hideAllCollapsibleElements(collapsibleTrigger);
          this.parentNode.classList.add('active');
        } else {
          hideAllCollapsibleElements(collapsibleTrigger);
        }

      }
    }

    function hideAllCollapsibleElements (collapsibleTrigger) {
      for (var i = 0; i < collapsibleTrigger.length; ++i) {
        collapsibleTrigger[i].parentNode.classList.remove('active');
      }
    }
  }

  //Show model table
  BDMPerformance.prototype.initShowModelTable = function () {
    var modelTrigger = document.getElementsByClassName('js-model__trigger');
    var modelContainer = document.getElementById('js-model__container');

    for (var i = 0; i < modelTrigger.length; ++i) {
      modelTrigger[i].onclick = function () {
        console.log(modelContainer);
        modelContainer.classList.add('active');
      }
    }
  }

}());

//BIRL!

var bdm = new BDMPerformance();

